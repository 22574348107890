import React, { useState, useEffect } from "react";

function Tweet(props){
    const [data, setData] = useState({})

    useEffect(() => {
        setData(props.data)
    }, [props])
    return (
        <a className="tweet" href={`https://www.x.com/i/web/status/${data.tweet_id}`} target="_blank" rel="noreferrer">
            {data.text}
        </a>
    )
}

export default Tweet