import React from 'react'

function Navbar(props) {
    
    return (
        <div id="navbar" className="row">
            <div id="logo" className="col text-center">D3PHCOM 💢</div>
            {/* <div id="logo" className="col-6">D3PHCOM 💢</div> */}
            {/* <div id="authentication" className="col-6 row">
                    <div className="nav-button" onClick={e => props.changeModal()}>Ukraine Map</div>
                    <div className="nav-button">Sign Up</div>
                    <div className="nav-button">Log In</div> 
            </div> */}
        </div>
    )
}

export default Navbar