import React from 'react'
import { useEffect, useState } from 'react'
import HvalWrapper from './HvalWrapper'
import TweetFeed from './TweetFeed'

function Content(props) {
    const [tweets, setTweets] = useState([])
    const [idString, setIdString] = useState("")
    const [mode, setMode] = useState("")
    

    useEffect(() => {
        setTweets(props.tweets)
        setMode(props.currentMode)
    }, [props])

    // updates idString
    function changeIdString(e) {
        setIdString(e.target.value)
    }

    function sendUserRequest() {
        props.createUser(idString)
    }
    return (
        <div id="content" className="text-center">
            Insert Twitter IDs to add VIP user, separated by comma (no space) and click 'Add'. <span className="emph-txt">Do not include a comma or space if there is only one Twitter ID</span>
            ...If the user already exists, their VIP status will be updated.
            {tweets.length < 1 && !props.loading ? <><div id="no-kw-selected">Current Score: {props.hvals}</div>
                <div className="row">
                <div className="input-group mb-3 col">
                    <span className="input-group-text" id="basic-addon1">#</span>
                    <input type="text" className="form-control" placeholder="Enter twitter IDs here" aria-label="Twitter ID" aria-describedby="basic-addon1" onKeyUp={changeIdString}/>
                    {/* props.createUser will take in whatever is inside the input from idString */}
                    <div onClick={sendUserRequest}><button className="btn btn-outline-secondary" type="button">Add</button></div>
                </div>
                <div className="input-group mb-3 col justify-content-center">
                    <button className="btn btn-outline-secondary dropdown-toggle" id="mode-dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">{mode}</button>
                    <ul className="dropdown-menu">
                        <li><a className="dropdown-item" href="#" onClick={e => {props.changeMode("widenet")}}>widenet</a></li>
                        <li><a className="dropdown-item" href="#" onClick={e => {props.changeMode("spearfishing")}}>spearfishing</a></li>
                    </ul>
                </div>
                
                </div> {/* end of row */}
                <div className="row">
                {props.successMsg ? ' Users added:' + props.successMsg : null}
                </div>
                </>
            
            : 
            props.loading ? <>
                <div id="no-kw-selected">
                
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>
            </> :
            <>
                {/* <HvalWrapper hvals={hvals}/>  */}
                <TweetFeed tweets={tweets}/>
            </>
            }
            
        </div>
    )
}

export default Content