import './App.css';
import Modal from 'react-modal'
import Navbar from './components/Navbar/Navbar';
import Manifest from './components/Manifest/Manifest'

import React, {useState, useEffect} from 'react'
import socketIOClient from "socket.io-client" // for backend comms
const ENDPOINT = "https://d3phcom.herokuapp.com" // for socket (from server)
// const ENDPOINT = "http://localhost:3001" // for socket (test in local)

function App() { 
  
  const [hvals, setHvals] = useState(0.0)
  const [tweets, setTweets] = useState([])
  const [kws, setKws] = useState([])
  const [socket, setSocket] = useState()
  const [loading, setLoading] = useState(false)
  const [showModal, toggleModal] = useState(false)
  const [successMsg, setSuccessMsg] = useState("")
  const [currentMode, setCurrentMode] = useState("");
  
  function changeModal() {
    if (showModal) {
      toggleModal(false)
    } else {
      toggleModal(true)
    }
  }

  function changeMode() {
    if (currentMode == "spearfishing"){
      socket.emit('mode', "widenet")
    } else {
      socket.emit('mode', "spearfishing")
    }
  }
  function getContent(kw) {
    socket.emit('changeKW', kw)
    console.log('sending request')
    setLoading(true)
  }
  function createUser(str) {
    socket.emit('createUser', str)
    console.log(typeof str)
    console.log('sending request for '+str+' to be created')
    setLoading(true)
  }
  useEffect(() => { // When this component mounts, (The whole App)
    Modal.setAppElement('#root')
    // Creates a connection to the server for real time updates
    const newSocket = socketIOClient(ENDPOINT, {
      transports: ['websocket', "polling"]
    })

    newSocket.on('connect', () => { // When the connection is established)
      console.log('connected to server')  
    })
    setSocket(newSocket) // Socket is sending an ARRAY of data
    newSocket.on('hvals', resp => { // on init the data is the HVALs. User must interact to retrieve the tweets connected to the HVALS
      setHvals(resp) // Creates new array with info
      setLoading(false)
    })
    newSocket.on('tweets', resp => {
      setTweets(resp)
      setLoading(false)
    })
    newSocket.on('kws', resp => {
      setKws(resp)
    })
    newSocket.on('successAddUser', resp => {
      // resp should be an arr of user statuses
      setLoading(false)
      console.log(resp)
      setSuccessMsg(resp)
    })
    newSocket.on('mode', resp => {
      setCurrentMode(resp)
      console.log('Someone changed the mode to '+resp)
    })
    return () => { // ensures there's no constant new sockets being created when a user exits the site
      newSocket.disconnect()
    }
  }, []) // do not remove empty array, it is a function of useEffect to prevent logical errors
  return (
    <div className="main-container">
        <Navbar changeModal={changeModal}/>
        <Manifest getContent={getContent} hvals={hvals} tweets={tweets} kws={kws} loading={loading} createUser={createUser} successMsg={successMsg} currentMode={currentMode} changeMode={changeMode}/>
        <Modal isOpen={showModal} onRequestClose={changeModal} contentLabel="View Post">
          <iframe src={`https://liveuamap.com`} title="View" style={{width: '100%', height: '100%'}} sandbox="allow-scripts"/>
        </Modal>
    </div>
  );
}  

export default App;
