import React from 'react'
import Tweet from './Tweet'
import { useState, useEffect } from 'react'

function TweetFeed(props){
    const [tweets, setTweets] = useState()

    useEffect(() => {
        if (props.tweets && props.tweets.length > 0){
            setTweets(props.tweets)
            console.log(props.tweets[0])
        }
    }, [props.tweets])
    return (
        <div id="tweet-feed-wrapper">
            <div id="tweet-container" className="row">
            {tweets ? tweets.map(tweet => {return <Tweet data={tweet}/>}
            ) : 'no length detected'}
            </div>
        </div>
    )
}

export default TweetFeed