import React from 'react'
import { useState, useEffect } from 'react'
import SidebarWrapper from './Sidebar/SidebarWrapper'
import ContentWrapper from './Content/ContentWrapper'

function Manifest(props){
    return(
        <div id="manifest" className="row my-3">
            {/* <SidebarWrapper getContent={props.getContent} kws={props.kws}/> */}
            <ContentWrapper tweets={props.tweets} hvals={props.hvals} loading={props.loading} createUser={props.createUser} successMsg={props.successMsg} changeMode={props.changeMode} currentMode={props.currentMode}/>
        </div>
    )
}

export default Manifest