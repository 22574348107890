import React from 'react'
import { useState, useEffect } from 'react'
import Content from './Content'

function ContentWrapper(props) {

    return (        
        <div id="content-wrapper" className="col-9">
            <Content tweets={props.tweets} hvals={props.hvals} loading={props.loading} createUser={props.createUser} successMsg={props.successMsg} changeMode={props.changeMode} currentMode={props.currentMode}/>
        </div>
    )
}

export default ContentWrapper